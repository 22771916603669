import SaveTheDatePhotoURL1 from '../../Assets/AP-M/Hor-1.png'
import SaveTheDatePhotoURL2 from '../../Assets/AP-M/Hor-2.png'
import SaveTheDatePhotoURL3 from '../../Assets/AP-M/Hor-3.png'
import SaveTheDatePhotoURL4 from '../../Assets/AP-M/Hor-4.png'

import SaveTheDatePhotoURLVertical1 from '../../Assets/AP-M/IMG_0618.jpeg'
import SaveTheDatePhotoURLVertical2 from '../../Assets/AP-M/IMG_0620.jpeg'
import SaveTheDatePhotoURLVertical3 from '../../Assets/AP-M/IMG_0649.jpeg'
import SaveTheDatePhotoURLVertical4 from '../../Assets/AP-M/IMG_0623.jpeg'
import SaveTheDatePhotoURLVertical5 from '../../Assets/AP-M/IMG_0657.jpeg'
import { WeddingInfo } from '../../Common'

import './PhotoComponent.css'

function PhotoComponent() {
    let ImgToUse = SaveTheDatePhotoURL1;

    if ((window.innerWidth / window.innerHeight) >= 1) {
        // If the window is a landscape view
        switch (Math.ceil(Math.random() * 4)) {
            case 1:
                ImgToUse = SaveTheDatePhotoURL1;
                break;
            case 2:
                ImgToUse = SaveTheDatePhotoURL2;
                break;
            case 3:
                ImgToUse = SaveTheDatePhotoURL3;
                break;
            case 4:
                ImgToUse = SaveTheDatePhotoURL4;
                break;
        }
    }
    else {
        // If the window is a portrait view
        switch (Math.ceil(Math.random() * 5)) {
            case 1:
                ImgToUse = SaveTheDatePhotoURLVertical1;
                break;
            case 2:
                ImgToUse = SaveTheDatePhotoURLVertical2;
                break;
            case 3:
                ImgToUse = SaveTheDatePhotoURLVertical3;
                break;
            case 4:
                ImgToUse = SaveTheDatePhotoURLVertical4;
                break;
            case 5:
                ImgToUse = SaveTheDatePhotoURLVertical5;
                break;
        }
    }

    let options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    let formattedDate: string = WeddingInfo.date.toLocaleDateString(undefined, options);

    return (
        <div>
            <div className='photoComponent'>
                <img className='photo' src={ImgToUse} alt="Sofía and Íker" />
                <div className='titleContainer'>
                    <div className='title'>{WeddingInfo.weddingName}</div>
                    <div className='date'>{formattedDate}</div>
                </div>
            </div>
        </div>

    );
}

export default PhotoComponent;